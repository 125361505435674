import axios from "axios"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  follow: null,
  followers: [],
}

export const getters = {
  ...BaseGetters,
  follow: (state) => {
    return state.follow
  },
  followers: (state) => {
    return state.followers
  },
}

export const actions = {
  ...BaseActions,
  fetchFollow: ({ commit, dispatch, getters }, payload) => {
    function buildQueryString(payload) {
      let queryString = `follows/find?q={`

      if (payload.child_type === "Report" || payload.child_type === "Company") {
        queryString += `"custom_attrs":[{"pattern":"follow_relational_data"},{"pattern":"follow_child_data"}],`
      }

      queryString += `"find_by":{"child_type": "${payload.child_type}", "child_id": ${payload.child_id}`

      if (payload.user_id) {
        queryString += `, "user_id": ${payload.user_id}`
      }

      queryString += `}}`
      return queryString
    }

    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .get(buildQueryString(payload))
        .then((response) => {
          commit("SET_FOLLOW", response.data.follow)
          commit("SET_LOADING", false)
          resolve(response.data.follow)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
  fetchFollowers: ({ commit, dispatch, getters }, payload) => {
    function buildQueryString(payload) {
      let queryString = `follows?q={`

      if (payload.child_type === "Report") {
        queryString += `"custom_attrs":[{"pattern": "follow_relational_data"}],`
      }

      queryString += `"find_by":{"child_type": "${payload.child_type}", "child_id": ${payload.child_id}`

      if (payload.user_id) {
        queryString += `, "user_id": ${payload.user_id}`
      }

      queryString += `}}`
      return queryString
    }

    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .get(buildQueryString(payload))
        .then((response) => {
          commit("SET_FOLLOWERS", response.data.follows)
          commit("SET_LOADING", false)
          resolve(response.data.follows)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          console.error(e)
          // reject(e)
        })
    })
  },
  createFollow: ({ commit, dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      let user = payload.user
      delete payload.user
      axios
        .post("follows", { follow: payload })
        .then((response) => {
          let follow = response.data.follow
          _.set(follow, "user", user)
          commit("PUSH_FOLLOWER", follow)
          commit("SET_LOADING", false)

          // Track follows on Amplitude
          dispatch(
            "amplitude/logEvent",
            {
              name: "Follow Created",
              properties: {
                user_id: follow.user_id,
                child_id: follow.child_id,
                child_type: follow.child_type,
              },
            },
            { root: true }
          )

          resolve(response.data.follow)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
  deleteFollow: ({ commit, dispatch, getters }, followId) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .delete(`follows/${followId}`)
        .then((response) => {
          commit("POP_FOLLOWER", followId)
          commit("SET_LOADING", false)
          resolve()
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
  SET_FOLLOW: (state, follow) => {
    state.follow = follow
  },
  SET_FOLLOWERS: (state, followers) => {
    state.followers = followers
  },
  PUSH_FOLLOWER: (state, follower) => {
    state.followers.push(follower)
  },
  POP_FOLLOWER: (state, followId) => {
    state.followers = _.filter(state.followers, (f) => f.id != followId)
  },
}
